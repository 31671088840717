.PrimaryContactForm {
  text-align: center;
  max-width: 450px;
  margin: 20px auto;
}
Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
