* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.bg{
  background:linear-gradient(rgba(250,0,0,0.5),transparent);
  background-color:orange /*this your primary color*/
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.service-item {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: white;
}

.service-item-title {
  min-width: 400px;
  height: 250px;
  border-radius: 5px;
  background-color: #3a6f8fd6;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.service-item-desc {
  padding: 1em;
}

.services {
  background-image: url('./images/imgServices.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
